export const opcionesPeso = [];

for (let peso = 30; peso <= 100; peso += 0.5) {
    opcionesPeso.push(`${peso} kg`);
}

export const opcionesEstatura = [];

for (let estatura = 130; estatura <= 200; estatura += 1) {
    opcionesEstatura.push(`${estatura} cm`);
}

export const opcionesTipoSangre = [
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' },
    { value: 'O+', label: 'O+' },
    { value: 'O-', label: 'O-' }
];