import React, { useState, useEffect } from "react";
import logoICE from './assets/images/Logo-ICE.png'
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";
import { db } from "./firebase/firebaseconfig";
import { query, where, getDocs, collection } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faComment } from '@fortawesome/free-solid-svg-icons';
import Footer from "./components/footer";

const InfoQR = () => {
    const location = useLocation();
    const idUnico = location.pathname.split("/")[2];

    const [userInfo, setUserInfo] = useState(null);
    const [infoNotFound, setInfoNotFound] = useState(false);

    useEffect(() => {
        console.log("ID único:", idUnico);

        if (!idUnico || idUnico.trim() === "") return;

        const fetchUserInfo = async () => {
            try {
                const userQuery = query(collection(db, "usuarios"), where("idUnico", "==", idUnico));
                const userQuerySnapshot = await getDocs(userQuery);

                if (userQuerySnapshot.empty) {
                    console.log("No se encontró información personal para este usuario");
                    setInfoNotFound(true);
                    return;
                }

                const userId = userQuerySnapshot.docs[0].id;

                const userInfoQuery = query(collection(db, "informacionPersonal"), where("userId", "==", userId));
                const userInfoQuerySnapshot = await getDocs(userInfoQuery);

                if (userInfoQuerySnapshot.empty) {
                    console.log("No se encontró información personal para este usuario");
                    setInfoNotFound(true);
                    return;
                }

                const userInfoData = userInfoQuerySnapshot.docs[0].data();
                setUserInfo(userInfoData);
            } catch (error) {
                console.error("Error al obtener información personal:", error);
            }
        };

        fetchUserInfo();
    }, [idUnico]);

    const calcularEdad = (fechaNacimiento) => {
        const hoy = new Date();
        const fechaNac = new Date(fechaNacimiento);
        let edad = hoy.getFullYear() - fechaNac.getFullYear();
        const mes = hoy.getMonth() - fechaNac.getMonth();
        if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
            edad--;
        }
        return edad;
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
        if (cleaned.length === 12 && cleaned.startsWith("57")) {
            const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]}`;
            }
        }
    
        if (cleaned.length === 12 && cleaned.startsWith("52")) {
            const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/);
            if (match) {
                return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
            }
        }
    
        if (cleaned.length === 10 && cleaned.startsWith("3")) {
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]} ${match[3]}`;
            }
        }
    
        return phoneNumber;
    };
    
    return (
        <div className="container-fluid vh-100 w-100 p-0">
            <Helmet>
                <title>QR info | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            {infoNotFound ? (
                    <div class="alert alert-warning m-5" role="alert">
                        <p>No se encontró información personal para este usuario.</p>
                    </div>
              
            ) : (
                userInfo ? (
                    <div>
                        <div className="text-center mt-3 mb-5">
                            <img src={logoICE} alt="Logo ICE" width="200px"></img>
                        </div>
                        <div className="form-qr-headings p-4 pb-0">
                            <h1>Información personal</h1>
                        </div>
                        <section className="form-qr p-4">
                            <div className="border-bottom mb-2 p-2">
                                <p><strong>{userInfo.nombres}</strong></p>
                                <p>{calcularEdad(userInfo.fechanacimiento)} años</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Tipo de sangre</span></p>
                                <p>{userInfo.tiposangre}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Peso</span></p>
                                <p>{userInfo.peso}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Estatura</span></p>
                                <p>{userInfo.estatura}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Condiciones médicas</span></p>
                                <p>{userInfo.condicionesmedicas}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Alergias y reacciones</span></p>
                                <p>{userInfo.alergiasyreacciones}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Medicamentos</span></p>
                                <p>{userInfo.medicamentos}</p>
                            </div>
                        </section>
                        <div className="form-qr-headings p-4 pb-0">
                            <h2 className="mt-4">Contactos de emergencia</h2>
                        </div>
                        <section className="form-qr p-4">
                            {userInfo.parentescoContactoEmergencia.map((parentesco, index) => (
                                <div className="border-bottom mb-2 p-2" key={index}>
                                    <p><span>{parentesco}</span></p>
                                    <p>{userInfo.nombresContactoEmergencia[index]}</p>
                                    <p>
                                        <a href={`tel:+${userInfo.celularContactoEmergencia[index].replace(/\D/g, '')}`}>
                                            {formatPhoneNumber(userInfo.celularContactoEmergencia[index])}
                                        </a>
                                    </p>
                                    <div className="row">
                                        <div className="col-5">
                                            <a href={`tel:${userInfo.celularContactoEmergencia[index].replace(/\D/g, '')}`}>
                                                <button type="button" className="btn btn-tel"><FontAwesomeIcon icon={faPhone} /> Llamar</button>
                                            </a>
                                        </div>
                                        <div className="col-7 d-flex justify-content-end">
                                            <a href={`https://wa.me/${userInfo.celularContactoEmergencia[index].replace(/\D/g, '')}?text=Hola%20${userInfo.nombresContactoEmergencia[index]}%20te%20escribo%20porque%20me%20encuentro%20con%20${userInfo.nombres}`}>
                                                <button type="button" className="btn btn-whatsapp"><FontAwesomeIcon icon={faComment} /> Enviar WhatsApp</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </section>
                        <Footer />
                    </div>
                ) : (
                    <div class="spinner-border m-5" role="status">
                        <span class="visually-hidden">Cargando información...</span>
                    </div>  
                )
            )}
        </div>
    );
};

export default InfoQR;