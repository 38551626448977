import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDRWE_gizxSwSKyGmk7Ms2S0ef2gJlcr7k",
  authDomain: "ice-id-7ca30.firebaseapp.com",
  projectId: "ice-id-7ca30",
  storageBucket: "ice-id-7ca30.appspot.com",
  messagingSenderId: "4060631404",
  appId: "1:4060631404:web:c1daeac11ca3d332716349"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };