import React, { useState, useEffect } from "react";
import logoICE from '../assets/images/Logo-ICE.png'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from "react-router-dom";
import { opcionesPeso, opcionesEstatura, opcionesTipoSangre } from '../assets/js/scripts';
import { db, auth } from "../firebase/firebaseconfig";
import { query, where, getDocs, collection, updateDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const userId = location.state?.userId;

    const [userInfo, setUserInfo] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(!!userId);
    const [isEditing, setIsEditing] = useState(false);
    const [editedUserInfo, setEditedUserInfo] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);

    const agregarContacto = () => {
        const nuevoContacto = {
            parentesco: '',
            nombres: '',
            celular: ''
        };
        setEditedUserInfo({
            ...editedUserInfo,
            parentescoContactoEmergencia: [...editedUserInfo.parentescoContactoEmergencia, nuevoContacto]
        });
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                if (!userId) return;
    
                const userInfoQuery = query(collection(db, "informacionPersonal"), where("userId", "==", userId));
                const querySnapshot = await getDocs(userInfoQuery);
    
                if (querySnapshot.empty) {
                    console.log("No se encontró información personal para este usuario");
                    return;
                }
    
                const userInfoData = querySnapshot.docs[0].data();
                setUserInfo(userInfoData);
            } catch (error) {
                console.error("Error al obtener información personal:", error);
            }
        };
    
        fetchUserInfo();
    }, [userId]);

    const handleEdit = () => {
        setIsEditing(true);
        setEditedUserInfo({ ...userInfo });
    };

    const handleSave = async () => {
        try {
            const infoPersonalQuery = query(collection(db, "informacionPersonal"), where("userId", "==", userInfo.userId));
            const infoPersonalSnapshot = await getDocs(infoPersonalQuery);
    
            if (!infoPersonalSnapshot.empty) {
               
                const infoPersonalDoc = infoPersonalSnapshot.docs[0];
    
                await updateDoc(infoPersonalDoc.ref, editedUserInfo);
    
                setUserInfo(editedUserInfo);
                setIsEditing(false);
                setSaveSuccess(true);
    
                setTimeout(() => {
                    setSaveSuccess(false);
                }, 3000);
            } else {
                console.error("No se encontró el documento de informacionPersonal para este usuario");
            }
        } catch (error) {
            console.error("Error al guardar la información personal:", error);
        }
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            setIsAuthenticated(false);
            navigate("/iniciar-sesion");
        } catch (error) {
            console.error("Error al cerrar sesión:", error);
        }
    };

    const calcularEdad = (fechaNacimiento) => {
        const hoy = new Date();
        const fechaNac = new Date(fechaNacimiento);
        let edad = hoy.getFullYear() - fechaNac.getFullYear();
        const mes = hoy.getMonth() - fechaNac.getMonth();
        if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
            edad--;
        }
        return edad;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUserInfo({ ...editedUserInfo, [name]: value });
    };

    const handleInputMatrizChange = (e, index, fieldName) => {
        const { value } = e.target;
        const updatedUserInfo = { ...editedUserInfo };
        const updatedArray = [...updatedUserInfo[fieldName]];
        updatedArray[index] = value;
        setEditedUserInfo({ ...updatedUserInfo, [fieldName]: updatedArray });
    };

    const handleDeleteContact = (index) => {
        const updatedContacts = [...editedUserInfo.parentescoContactoEmergencia];
        updatedContacts.splice(index, 1);
        setEditedUserInfo(prevState => ({
            ...prevState,
            parentescoContactoEmergencia: updatedContacts
        }));
    }
    
    return (
        <div className="container mt-5">
            <Helmet>
                <title>Home | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            {isAuthenticated ? (
                userInfo ? (
                    <div className="row">
                        <div className="text-center mb-5">
                            <img src={logoICE} alt="Logo ICE" width="200px"></img>
                        </div>
                        <div className="col-6">
                            {!isEditing ? (
                                <button type="button" className="btn btn-primary" onClick={handleEdit}>
                                    <FontAwesomeIcon icon={faEdit} /> Editar Información
                                </button>
                            ) : (
                                <button type="button" className="btn btn-success" onClick={handleSave}>
                                    <FontAwesomeIcon icon={faSave} /> Actualizar Información
                                </button>
                            )}
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <button type="button" className="btn btn-secondary" onClick={handleLogout}>
                                <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar Sesión
                            </button>
                        </div>
                        <section>
                            {saveSuccess && (
                                <div className="alert alert-success mt-3" role="alert">
                                    ¡La información fue guardada exitosamente!
                                </div>
                            )}
                        </section>
                        <section className="mt-3 form-informacion-personal p-3">
                            <h1>Información Personal</h1>
                            {!isEditing ? (
                                <>
                                    <p>{userInfo.nombres}</p>
                                    <p>Edad: {calcularEdad(userInfo.fechanacimiento)} años</p>
                                    <p>Tipo de sangre: {userInfo.tiposangre}</p>
                                    <p>Peso: {userInfo.peso}</p>
                                    <p>Estatura: {userInfo.estatura}</p>
                                    <p>Condiciones médicas: {userInfo.condicionesmedicas}</p>
                                    <p>Alergias y reacciones: {userInfo.alergiasyreacciones}</p>
                                    <p>Medicamentos: {userInfo.medicamentos}</p>
                                    <p>Notas: {userInfo.notas}</p>
                                </>
                            ) : (
                                <>
                                    <div className="mb-3">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="nombres" 
                                            value={editedUserInfo?.nombres || ""} 
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="fechanacimiento" className="col-5 col-form-label">Fecha de nacimiento</label>
                                        <div className="col-7">
                                            <input type="date" 
                                                className="form-control" 
                                                name="fechanacimiento" 
                                                value={editedUserInfo?.fechanacimiento || ""} 
                                                onChange={handleInputChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="tipoSangre" className="col-5 col-form-label">Tipo de sangre</label>
                                        <div className="col-7">
                                            <select
                                                id="tipoSangre" 
                                                className="form-select"
                                                name="tiposangre"
                                                value={editedUserInfo?.tiposangre || ""}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option disabled value="">Selecciona...</option>
                                                {opcionesTipoSangre.map((opcion, index) => (
                                                    <option key={index} value={opcion.value}>
                                                        {opcion.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="peso" className="col-5 col-form-label">Peso</label>
                                        <div className="col-7">
                                            <select
                                                id="peso" 
                                                className="form-select"
                                                name="peso"
                                                value={editedUserInfo?.peso || ""}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option disabled value="">Selecciona...</option>
                                                {opcionesPeso.map((opcion, index) => (
                                                    <option key={index} value={opcion}>
                                                        {opcion}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="estatura" className="col-5 col-form-label">Estatura</label>
                                        <div className="col-7">
                                            <select
                                                id="estatura"
                                                className="form-select"
                                                name="estatura"
                                                value={editedUserInfo?.estatura || ""}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option disabled value="">Selecciona...</option>
                                                {opcionesEstatura.map((opcion, index) => (
                                                    <option key={index} value={opcion}>
                                                        {opcion}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="condicionesMedicas" className="col-5 col-form-label">Condiciones médicas</label>
                                        <textarea
                                            id="condicionesMedicas"
                                            className="form-control"
                                            name="condicionesmedicas"
                                            value={editedUserInfo?.condicionesmedicas || ""}
                                            onChange={handleInputChange} 
                                            rows="1">     
                                        </textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="alergiasYReacciones" className="col-5 col-form-label">Alergias y reacciones</label>
                                        <textarea
                                            id="alergiasYReacciones"
                                            className="form-control"
                                            name="alergiasyreacciones"
                                            value={editedUserInfo?.alergiasyreacciones || ""}
                                            onChange={handleInputChange} 
                                            rows="1">     
                                        </textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="medicamentos" className="col-5 col-form-label">Medicamentos</label>
                                        <textarea
                                            id="medicamentos"
                                            className="form-control"
                                            name="medicamentos"
                                            value={editedUserInfo?.medicamentos || ""}
                                            onChange={handleInputChange} 
                                            rows="1">     
                                        </textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="notas" className="col-5 col-form-label">Notas</label>
                                        <textarea
                                            id="notas"
                                            className="form-control"
                                            name="notas"
                                            value={editedUserInfo?.notas || ""}
                                            onChange={handleInputChange} 
                                            rows="1">     
                                        </textarea>
                                    </div>
                                </>
                            )}
                            <h2 className="mt-5">Contactos de emergencia</h2>
                            {isEditing ? (
                                <>
                                <div>
                                    {editedUserInfo.parentescoContactoEmergencia.map((parentesco, index) => (
                                        <div className="card mb-3" key={index}>
                                            <div className="card-header">
                                                <input 
                                                    type="text"
                                                    className="form-control"
                                                    name={`parentescoContactoEmergencia[${index}]`} 
                                                    value={editedUserInfo.parentescoContactoEmergencia[index]} 
                                                    onChange={(e) => handleInputMatrizChange(e, index, 'parentescoContactoEmergencia')}
                                                    placeholder="Parentesco"
                                                />
                                            </div>
                                            <div className="card-body">
                                                <input 
                                                    type="text"
                                                    className="form-control mb-2"
                                                    name={`nombresContactoEmergencia[${index}]`} 
                                                    value={editedUserInfo.nombresContactoEmergencia[index]} 
                                                    onChange={(e) => handleInputMatrizChange(e, index, 'nombresContactoEmergencia')}
                                                    placeholder="Nombres"
                                                />
                                                <input 
                                                    type="tel"
                                                    className="form-control"
                                                    name={`celularContactoEmergencia[${index}]`} 
                                                    value={editedUserInfo.celularContactoEmergencia[index]} 
                                                    onChange={(e) => handleInputMatrizChange(e, index, 'celularContactoEmergencia')}
                                                    placeholder="Indicativo más celular, sin espacios ni caracteres especiales"
                                                />
                                            </div>
                                            <div className="card-footer row">
                                                <div className="col-6">
                                                    <button type="button" className="btn btn-success" onClick={handleSave}>Actualizar este contacto</button>
                                                </div>
                                                <div className="col-6 d-flex justify-content-end">
                                                    <button type="button" className="btn btn-danger" onClick={handleDeleteContact}>Eliminar este contacto</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div>
                                        <button type="button" className="btn btn-primary" onClick={agregarContacto}>Agregar otro contacto</button>
                                    </div>
                                </div>
                                </>
                            ) : (
                                <>
                                    {userInfo.parentescoContactoEmergencia.map((parentesco, index) => (
                                        <div className="card mb-3" key={index}>
                                            <div className="card-header">
                                                {parentesco}
                                            </div>
                                            <div className="card-body">
                                                <p>{userInfo.nombresContactoEmergencia[index]}</p>
                                                <p>{userInfo.celularContactoEmergencia[index]}</p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </section>
                    </div>
                ) : (
                    <p>Cargando información...</p>
                )
            ) : (
                <p>Por favor inicia sesión haciendo click <Link to="/iniciar-sesion">aquí</Link>.</p>
            )}
        </div>
    );
};

export default Home;